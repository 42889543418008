<template>
    <div class="shopBox">
        <div class="shopHeader">
            <div class="products-grid-header-left search-header-left">
                <div class="breadcrumbs">
                    <div class="crumb">
            <span class="inner">
              <span class="icon"></span>
              <span class="text">{{ parentCategoryName }}</span>
            </span>
                    </div>
                    <div class="crumb" v-if="subCategoryName">
            <span class="inner">
              <span class="icon"></span>
              <span class="text">{{ subCategoryName }}</span>
            </span>
                    </div>
                </div>
            </div>
            <div
                class="switch-view-refinements-container"
                :class="isFixed === true ? 'isFixed' : ''"
            >
                <div class="refinements refinements-wrapper ready">
                    <ul class="refinements-buttons">
                        <li class="refinements-filters">
                            <span class="text" @click="clickFilter">Filter</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="products-grid">
            <div class="products-grid-inner">
                <ul class="products products-grid-list search-grid-list">
                    <li
                        class="productSlot products-grid-item ready"
                        v-for="(item, index) in productsListArr"
                        :key="index"
                        @click="toProductDetail(item.id)"
                    >
                        <div class="product-slot-inner">
                            <div class="product-slot-inner-wrapper">
                                <div class="product-slot-image">
                                    <div class="product-slot-image-primary">
                                        <img :src="item.thumbnail"/>
                                    </div>
                                </div>
                                <div class="product-slot-infos ">
                                    <h3 class="product-slot-infos-title">
                                        <span>{{ item.name }}</span>
                                    </h3>
                                    <div class="product-slot-infos-price">
                                        <div class="priceUpdater">
                                            <div class="itemPrice">
                        <span class="price">
                          <span class="currency">HK$ </span>
                          <span class="value">{{ fenToYuan(item.price) }}</span>
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!--    <div class="loadMore">-->
        <!--      <button>Load more</button>-->
        <!--    </div>-->
        <!--    <div class="informationMessage">-->
        <!--      <div class="information-message-holder">-->
        <!--        <div class="information-message-content">-->
        <!--          <p>Complimentary Shipping</p>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--    尺码的选择-->
        <div class="dialog-boxs">
            <el-drawer
                :visible.sync="sortBox"
                title="Filters"
                direction="rtl"
                :modal="false"
                size="100%"
                class="sortDialog">
                <div class="sort-content">
                    <div>Order by price</div>
                    <div class="sort-choose">
                        <el-radio v-model="priceSorter" label="asc" class="sort-choose-text asc" @change="filter">
                            Ascending Price
                        </el-radio>
                        <el-radio v-model="priceSorter" label="desc" class="sort-choose-text desc" @change="filter">
                            Descending Price
                        </el-radio>
                    </div>
                </div>

            </el-drawer>
        </div>
    </div>
</template>

<script>
import {fetchProductsList} from "@/api/api";
import {f, priceTransform} from "@/utils/util";

export default {
    name: "shopList",
    data() {
        return {
            priceSorter: "",
            sortBox: false,
            showContent: false,
            checkList: [""],
            checkArr: [
                "Beige",
                "Black",
                "Blue",
                "Brown",
                "Green",
                "Multicolor",
                "Red",
                "White"
            ],
            choseRadio: 1,
            isFixed: "",
            productsListArr: [],
            productId: "",
            parentCategoryName: "",
            subCategoryName: ""
        };
    },
    mounted() {
        if (this.$route.query.keyword != undefined) {
            this.productList("", "", this.$route.query.keyword);
        } else {
            this.productList(
                this.$route.query.parentCategoryId
                    ? this.$route.query.parentCategoryId
                    : "",
                this.$route.query.subCategoryId ? this.$route.query.subCategoryId : "",
                ""
            );
        }
        // this.productList(this.$route.query.parentCategoryId, this.$route.query.subCategoryId,this.$route.query.keyword);
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        fenToYuan(fen) {
            return priceTransform(fen);
        },
        async productList(parentId, subId, keyword, filter = 0) {
            const response = await fetchProductsList(parentId, subId, keyword, filter);
            if (response.code === 0) {
                this.parentCategoryName = response.data.parentCategoryName;
                this.subCategoryName = response.data.subCategoryName;
                this.productsListArr = response.data.list;
            }
            console.log("列表：", f(this.productsListArr));
        },
        clickFilter() {
            this.sortBox = true;
        },
        toProductDetail(id) {
            this.productId = id;
            this.$router.push({
                name: "productDetail",
                params: {
                    id
                }
            });
        },
        handleScroll() {
            this.$nextTick(() => {
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollTop > 10) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
            });
        },
        filter(e) {
            this.priceSorter = e;
            switch (this.priceSorter) {
                case "asc":
                    this.productList(
                        this.$route.query.parentCategoryId ? this.$route.query.parentCategoryId : "",
                        this.$route.query.subCategoryId ? this.$route.query.subCategoryId : "",
                        this.$route.query.keyword ? this.$route.query.keyword : "",
                        1
                    );
                    break;
                case "desc":
                    this.productList(
                        this.$route.query.parentCategoryId ? this.$route.query.parentCategoryId : "",
                        this.$route.query.subCategoryId ? this.$route.query.subCategoryId : "",
                        this.$route.query.keyword ? this.$route.query.keyword : "",
                        2
                    );
                    break;
            }

            this.sortBox = false;
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }
};
</script>
<style lang="scss" scoped>
.sortDialog {
    margin-left: auto;
    margin-right: auto;

    .sort-content {
        margin: 8rem 5rem;

        .sort-choose {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;

            .sort-choose-text {
                margin-bottom: .8rem;
            }

            .sort-choose-text:hover {
                border-bottom: 1px solid #212121;
            }

            .sort-choose-text.asc {
                width: 8rem;
            }

            .sort-choose-text.desc {
                width: 8.5rem;
            }
        }
    }
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #2c3e50;
    font-size: 0.8125rem;
}

::v-deep .el-radio {
    color: #2c3e50;
    font-size: 0.8125rem;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #212121;
    background: #212121;
    width: 8px;
    height: 8px;
}

::v-deep .el-radio__inner {
    border: 1px solid #212121;
    width: 8px;
    height: 8px;
}

::v-deep .el-radio__inner::after {
    background-color: #212121;
}

::v-deep .el-radio__label {
    font-size: 0.8125rem;
}

a {
    color: inherit;
    position: relative;
}

h3,
h2 {
    font-weight: normal;
    color: inherit;
    font-size: 1em;
}

.shopHeader {
    margin: 0 auto;
    text-align: center;
}

.products-grid-header-left {
    padding: 0.9375rem;
    border-top: 1px solid #e1e0de;
    border-bottom: 1px solid #e1e0de;

    .crumb {
        position: relative;
        padding: 0 0.8125rem;
    }
}

.breadcrumbs {
    zoom: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.breadcrumbs:before {
    content: " ";
    display: table;
}

.crumb:before {
    content: "·";
    margin-right: 3px;
    position: absolute;
    top: 0;
    left: -1px;
}

.crumb:first-child:before {
    display: none;
}

.refinements-buttons {
    display: flex;
    font-style: italic;
    //height: 3.125rem;
    //border-bottom: 1px solid #e1e0de;
}

.refinements-buttons .text {
    cursor: pointer;
    margin-left: auto;
    margin-right: 0.625rem;
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 0.0625rem;
    border-bottom-color: transparent;
    position: relative;
}

.refinements-buttons li.refinements-filters .text:after {
    content: "+";
    display: inline-block;
    margin-left: 5px;
}

.products-grid {
    overflow: hidden;
    padding-top: 0.9375rem;
}

.products-grid-inner {
    margin: 0 auto;
    max-width: 100%;
    width: calc(100% - 0.625rem * 2);
}

.products-grid-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.125rem;
}

.product-slot-inner {
    border: 0 none;
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
}

.product-slot-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .product-slot-image {
        width: 100%;
        display: block;
        position: relative;
        background-color: #f8f7f5;
    }

    .product-slot-image:before {
        content: "";
        display: block;
        padding-bottom: 150%;
    }
}

.product-slot-image-primary {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;

    img {
        display: block;
        width: 100%;
        height: 100%;
        //top: 50%;
        //position: absolute;
        //transform: translateY(-50%);
        //left: 0;
    }
}

.product-slot-infos {
    flex-grow: 1;
    padding: 0.4375rem 1.3125rem 0.375rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
}

.product-slot-infos-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    order: 0;
    text-align: center;
    line-height: 1.1875rem;
    min-height: 1.25rem;
    -webkit-line-clamp: 2;
    max-height: 2.4375rem;
}

.product-slot-infos-price {
    .itemPrice {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
}

.sidebar-content {
    width: 100%;
    padding: 3.125rem;
    //height: 100%;
    //overflow-y: auto;
    flex-grow: 1;
    min-width: 1px;

    section {
        margin-bottom: 2.1875rem;

        .title {
            padding: 0 0 1.0625rem;
        }
    }

    .sidebar-col-full {
        flex-basis: 100%;
    }

    .showCont {
        ::v-deep .el-checkbox {
            color: #212121;
        }

        ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #212121;
        }

        ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background: #212121;
            border-color: #212121;
        }

        ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
            display: none;
        }
    }

    .el-radio-group {
        display: flex;
        flex-direction: column;

        ::v-deep .el-radio {
            margin-top: 0.8rem;
            color: #212121;
        }

        ::v-deep .el-radio__input.is-checked + .el-radio__label {
            color: #212121;
        }

        ::v-deep .el-radio__input.is-checked .el-radio__inner {
            background: #212121;
            border-color: #212121;
        }
    }
}

.sidebar-buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    height: 3.125rem;
    min-width: 1px;

    button {
        background-color: transparent;
        color: #212121;
        width: auto;
        display: block;
        border: none;
    }
}

.products-grid-item {
    display: flex;
    //width: 50%;
    width: 48.8%;
    padding: 0 0.125rem 0.9375rem;

    &:hover {
        cursor: pointer;
    }
}

.priceUpdater {
    text-align: center;
}

.switch-view-refinements-container {
    display: flex;
}

.switchView {
    font-style: italic;
    margin-left: auto;
    display: flex;
    margin-right: 2.5rem;

    span {
        margin-right: 0.625rem;
    }
}

.loadMore {
    button {
        margin-top: 7.75rem;
        background-color: #e1e0de;
        border-width: 0;
        color: #212121;
        display: block;
        padding: 1rem 3.125rem 0.9375rem;
        text-align: center;
        width: 100%;
        margin: 7.4375rem auto 2rem;
    }

    button:hover {
        cursor: pointer;
    }
}

@media (max-width: 1280px) {

    .sortDialog {
        margin-left: auto;
        margin-right: auto;

        .sort-content {
            margin: 1.875rem;

            .sort-choose {
                display: flex;
                flex-direction: column;
                margin-top: 2rem;

                .sort-choose-text {
                    margin-bottom: .8rem;
                }

                .sort-choose-text:hover {
                    border-bottom: 1px dotted #212121;
                }

                .sort-choose-text.asc {
                    width: 7.3rem;
                }

                .sort-choose-text.desc {
                    width: 7.8rem;
                }
            }
        }
    }
    .shopBox {
        margin-top: 3.125rem;
        max-height: calc(100% - 3.125rem);
        margin-bottom: 10rem;
    }
    .switchView {
        padding-top: 0.9375rem;
        float: left;
        width: 61%;
        padding-left: 1.1875rem;
        font-style: normal;
        margin: 0;
        //height: 3.125rem;
    }
    .switch-view-refinements-container {
        border-bottom: 1px solid #e1e0de;
    }
    .refinements {
        flex-basis: 100%;
        //padding-right: 1.1875rem;
        text-align: right;
    }
    .refinements-buttons {
        display: flex;
        height: 3.125rem;
        line-height: 3.125rem;

        .refinements-filters {
            flex-basis: 100%;
        }
    }
    .isFixed {
        width: 100%;
        position: fixed;
        left: 0;
        top: 3.125rem;
        z-index: 22;
        background: #fff;
        border-top: 1px solid #e1e0de;
    }
    ::v-deep .el-drawer__header {
        color: #212121;
        text-align: center;
        border-top: 1px solid #e1e0de;
        border-bottom: 1px solid #e1e0de;
        padding: 1rem 0.5rem;
    }
    ::v-deep .el-drawer__header > :first-child {
        padding-left: 2rem;
    }
    .sidebar-content {
        padding-top: 0;
    }
    ::v-deep .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    ::v-deep .el-checkbox {
        flex-basis: calc(50% - 1.25rem);
    }
    ::v-deep .el-drawer {
        width: 100% !important;
        //margin-top: 3.125rem;
        //height: calc(100% - 3.125rem) !important;
        box-shadow: none;
    }
}

@media (min-width: 768px) {
    .shopBox {
        margin-bottom: 9.375rem;
        min-height: calc(var(--inner-height) - 5rem);

        .shopHeader {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            min-height: 0;

            .products-grid-header-left {
                border: 0 none;
                padding-left: 0;
                padding-bottom: 0;
                padding-top: 0;
                text-align: left;

                .breadcrumbs {
                    display: block;
                    margin-left: -0.8125rem;
                }

                .crumb {
                    float: left;
                }
            }

            .switch-view-refinements-container {
                .refinements-buttons {
                    border-bottom: 0;
                    height: auto;

                    .text {
                        margin-right: 0;
                        font-style: italic;
                        padding-bottom: 0.125rem;
                    }
                }

                li.refinements-filters {
                    border-right: 0;
                    padding: 0;
                    white-space: nowrap;
                }
            }

            .products-grid-inner {
                width: calc(100% - 1.875rem * 2);
            }

            .product-slot-infos-link {
                padding-bottom: 0.125rem;
            }
        }

        .product-slot-infos {
            padding: 0.625rem 0 0.375rem;
        }

        .products-grid-item {
            padding-bottom: 1.5rem;
        }

        .product-slot-infos-title {
            //-webkit-line-clamp: 1;
            //max-height: 1.25rem;
            height: 2.4375rem;

            span {
                padding-bottom: 0.125rem;
            }
        }

        .product-slot-infos-price {
            font-size: 0.6875rem;
            line-height: 2.5rem;
            //line-height: .8125rem;
        }

        .refinements-color {
            margin-bottom: 1.1875rem;

            .title {
                padding: 0 0 1.1875rem;
            }
        }

        ::v-deep .el-drawer {
            width: 24.25rem !important;
        }

        .sidebar-buttons {
            position: relative;
            height: auto;
            width: auto;
            display: block;
            margin-top: 1.1875rem;
            padding: 0 3.375rem;
        }

        .loadMore {
            button {
                max-width: 18.75rem;
                padding: 0.3125rem 0.3125rem 0.375rem;
            }
        }
    }
    .sidebar-content {
        display: flex;
        flex-wrap: wrap;
        //margin-top: 7.9375rem;
        margin-top: 5rem;
        padding: 0 3.375rem;
        justify-content: space-between;

        .sidebar-col-half {
            flex-basis: 7.8125rem;
            padding: 0;
        }

        section {
            margin-bottom: 1.1875rem;
        }
    }
}

@media (min-width: 1024px) {
    .products-grid-list {
        margin: 0 -2.5rem;
    }
    .product-slot-infos {
        margin: 0 -0.625rem;
        padding: 0.6875rem 0 0.625rem;
        flex-direction: row;
    }
    .product-slot-infos-title {
        max-height: none;
        height: 2.4375rem;
        -webkit-line-clamp: 2;
        text-align: left;
        padding: 0 0 1px 0.625rem;
        max-width: calc(100% - 5.375rem - 1.25rem);
    }
    .product-slot-infos-price {
        text-align: right;
        padding: 0.25rem 0.625rem 0;
        margin: 0;
        //width: 6.625rem;
        font-size: 0.6875rem;
        line-height: 0.8125rem;
    }
    .products-grid-item {
        //flex: 1;
        width: 24.6%;
        padding: 0 2.5rem 1.3125rem;
    }
    .itemPrice {
        justify-content: flex-end;
    }
    .product-slot-infos-price {
        text-align: right;
    }
    .sidebar-content {
        padding: 2px 3.0625rem;
    }
    ::v-deep .el-drawer {
        width: 23.625rem !important;
    }
}

@media (min-width: 1280px) {
    ::v-deep .sortDialog .el-drawer__header span {
        display: none;
    }
    ::v-deep .sortDialog .el-drawer__header {
        justify-content: flex-end;
    }

    .shopBox {
        width: 100%;
        max-width: 100%;
        margin-bottom: 5.625rem;
        min-height: calc(var(--inner-height) - 6.875rem);

        .shopHeader {
            margin-top: 2.9375rem;
            width: 74%;
            display: flex;
            justify-content: space-between;
        }

        .products-grid-inner {
            width: 74%;

            .products-grid-list {
                margin: 0 -3.5vw;

                .products-grid-item {
                    padding: 0 3.5vw 1rem;
                }
            }
        }

        .product-slot-infos-title {
            padding: 0 0.625rem 1px;
        }

        .sidebar-content {
            //margin-top: 10.6875rem;
            padding: 0 12%;
            justify-content: flex-start;

            .sidebar-col-half {
                margin-right: 1.875rem;
            }
        }

        ::v-deep .el-drawer {
            width: 33% !important;
        }

        .sidebar-buttons {
            padding: 0 12%;
        }
    }
    .products-grid-item:hover {
        h3 {
            span {
                border-bottom: 1px solid currentColor;
            }
        }
    }
}
</style>
